
  import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
  import { defineComponent, reactive } from 'vue'
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { useStore } from 'vuex'
  export default defineComponent({
    components: {
      UserOutlined,
      LockOutlined,
    },
    setup() {
      const store = useStore()
      const modelRef = reactive({
        username: '',
        password: '',
      })
      const rulesRef = reactive({
        username: [
          {
            required: true,
            message: '请输入登录账户！',
            trigger: 'change',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入登录密码！',
            trigger: 'change',
          },
        ],
      })
      const { validate, validateInfos } = useForm(modelRef, rulesRef)
      const handleSubmit = (e: any) => {
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }
      const onSubmitForm = () => {
        store.dispatch('auth/loginAction', modelRef)
      }
      console.log(location, '登陆')
      return {
        modelRef,
        rulesRef,
        handleSubmit,
        validateInfos,
      }
    },
  })
